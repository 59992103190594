.newsletter-subs .newsletters {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.newsletter-subs .newsletter-require-account {
    margin-bottom: 20px;
}

@supports (display:grid) {
    .newsletter-subs .newsletters {
        display: grid;
    }
}

.newsletter-subs .newsletter {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
}

.newsletter-subs .newsletter.no-icon {
    display: block;
}

.newsletter-subs .newsletter:after {
    position: absolute;
    width: 100%;
    height: 1px;
    z-index: -1;
    border-top: 1px dashed #ccc;
}

.newsletter-subs .newsletter:before {
    position: absolute;
    width: 1px;
    height: 100%;
    z-index: -1;
    border-left: 1px dashed #ccc;
}

/* Details */
.newsletter-subs .newsletter-details h4 {
    margin-top: 5px;
}

.newsletter-subs .newsletter-details label {
    cursor: pointer;
    margin-bottom: 0;
}

/* Footer */
.newsletter-subs .newsletter-footer {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 10px;
}

@media (min-width: 480px) {
    .newsletter-subs .newsletter-footer {
        flex-direction: row;
        gap: 18px;
    }
}

@media (min-width: 1200px) {
    .newsletter-subs .newsletter-footer {
        flex-direction: row;
        gap: 18px;
    }
}

/* Switch */
@supports (-webkit-appearance:none) or (-moz-appearance:none) {
    .newsletter-subs .switch:after {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: #C0C0C0;
        transform: translateX(0);
        transition: transform 0.3s ease, opacity 0.2s;
    }

    .newsletter-subs .switch {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        margin: 0;
        width: 38px;
        border-radius: 11px;
        cursor: pointer;
        border: 1px solid #C0C0C0;
        background: #fff;
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    .newsletter-subs .switch:checked {
        background: #305b80;
        border: 1px solid #305b80;
    }

    .newsletter-subs .switch:checked:after {
        background: #fff;
        transform: translateX(17px);
    }

    .newsletter-subs .switch:hover:not(:checked):not(:disabled) {
        border-color: #305b80;
    }

    .newsletter-subs .switch:focus {
        box-shadow: 0 0 0 2px rgba(48, 91, 128, 0.3);
        outline: none;
    }
}

.newsletter-subs .newsletter-freq {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.newsletter-subs .newsletter-freq i,
.newsletter-subs .newsletter-freq svg {
    color: #777;
}

@media (max-width: 649px) {
    .newsletter-subs .newsletter {
        position: relative;
    }

    .newsletter-subs .newsletter:nth-child(n+2):after {
        content: '';
        top: -16px;
    }

    .newsletter-subs .newsletter:last-child {
        padding-bottom: 16px;
    }
}

@media (min-width: 650px) and (max-width: 991px) {
    .newsletter-subs .newsletter {
        display: block;
        grid-template-columns: auto;
        gap: 10px;
    }
}

@media (min-width: 650px) and (max-width: 1199px) {
    .newsletter-subs .newsletters {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;
    }

    .newsletter-subs .newsletter:nth-child(1):before {
        content: '';
        height: calc(100% - 20px);
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .newsletter-subs .newsletter:nth-child(even) {
        position: relative;
    }

    .newsletter-subs .newsletter:nth-child(even):after {
        content: '';
        left: calc(-100% - 48px);
        width: calc(200% + 48px);
        top: calc(100% + 24px);
    }

    .newsletter-subs .newsletter:last-child {
        padding-bottom: 24px;
    }
}

@media (min-width: 992px) {
    .newsletter-subs .newsletter {
        gap: 20px;
    }
}

@media (min-width: 1200px) {
    .newsletter-subs .newsletters {
        grid-template-columns: repeat(3, 1fr);
        gap: 60px;
    }

    .newsletter-subs .newsletter:nth-child(1):before {
        content: '';
        height: calc(100% - 20px);
        top: 10px;
        left: calc(((100% - 120px) / 3) + 30px);
    }

    .newsletter-subs .newsletter:nth-child(2):before {
        content: '';
        height: calc(100% - 20px);
        top: 10px;
        right: calc(((100% - 120px) / 3) + 30px);
    }

    .newsletter-subs .newsletter:nth-child(3n) {
        position: relative;
    }

    .newsletter-subs .newsletter:nth-child(3n):after {
        content: '';
        left: calc(-200% - 120px);
        width: calc(300% + 120px);
        top: calc(100% + 30px);
    }

    .newsletter-subs .newsletter:last-child {
        padding-bottom: 30px;
    }
}

.newsletter-subs .newsletter:last-child:after {
    content: none;
}

.newsletter-subs .newsletter-form-popup {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 0;
    background-color: #305b80;
    color: #fff;
    box-shadow: 0 -2px 6px 0 rgba(25, 37, 56, 0.1);
    transform: translateY(120%);
    transition: transform 200ms ease;
    z-index: 10;
}

.newsletter-subs .newsletter-form-popup.active {
    transform: none;
}

.newsletter-subs .newsletter-form-inner {
    padding: 0 15px;
    margin: auto;
}

.newsletter-subs .newsletter-form-popup h3 {
    line-height: 1.2;
}
    
.newsletter-subs .newsletter-form-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.newsletter-subs .newsletter-form-field {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 20px;
}

.newsletter-subs .newsletter-form-input {
    background-color: transparent;
    border: 0;
    box-shadow: 0 1px 0 0 #000;
    transition: box-shadow 200ms ease;
    position: relative;
    width: 100%;
    padding: 0;
    font-weight:;
    letter-spacing:;
}

.newsletter-subs .newsletter-form-input:focus,
.newsletter-subs .newsletter-form-input:focus-visible {
    box-shadow: 0 2px 0 0 #000;
    border: 0;
    outline: 0;
}

.newsletter-subs .newsletter-form-input:-webkit-autofill {
    box-shadow: 0 2px 0 0 #000, 0 0 0px 1000px #305b80 inset;
    background-color: transparent;
}

.newsletter-subs .newsletter-form-label {
    position: absolute;
    left: 0;
    opacity: 0.5;
    transition: all 200ms ease;
    color: #fff;
    font-weight: normal;
    letter-spacing:;
}

.newsletter-subs .newsletter-form-input:focus + .newsletter-form-label,
.newsletter-subs .newsletter-form-input.filled + .newsletter-form-label,
.newsletter-subs .newsletter-form-input:-webkit-autofill + .newsletter-form-label{
    text-transform: uppercase;
    font-size: 11px;
    opacity: 1;
    transform: translate(0, -150%);
}

.newsletter-subs .newsletter-form-disclaimer {
    opacity: 0.5;
}

.newsletter-subs .newsletter-form-submit-container {
    padding-top: 10px;
    padding-bottom: 5px;
}

.newsletter-subs .newsletter-form-submit {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    padding: 8px 16px;
    border-radius: 0; 
    background-color: #000;
    color: #fff;
    flex: 0 1 auto;
    text-decoration: none;
    transition: background-color 250ms ease;
}

.newsletter-subs .newsletter-form-submit:hover {
    text-decoration: none;
}

@media (min-width: 480px) {
    .newsletter-subs .newsletter-form-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 650px) {
    .newsletter-subs .newsletter-form-fields {
        grid-template-columns: 3fr 2fr 2fr;
        gap: 0 15px;
    }
}

@media (min-width: 768px) {
    .newsletter-subs .newsletter-form-fields {
        grid-template-columns: 3fr 2fr 2fr auto;
    }
}